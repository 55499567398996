import { useMediaQuery } from "@mui/material";
import React from "react";

const ServicePageExplore = () => {
  const isMobile = useMediaQuery("(max-width:547px)");
  const isTab = useMediaQuery("(max-width:1024px)");
  return (
    <div
      className="flex relative"
      style={{
        gap: "18px",
        justifyContent: "flex-end",
        alignItems: isMobile && "flex-start",
        height: isMobile ? "730px" : isTab ? "580px" : "1150px",
        padding: isMobile && "8px",
        marginTop:isMobile&&"150px"||isTab&&"200px"||"0px",
        marginBottom:isMobile&&"0px"||isTab&&"200px"||"0px",
        flexDirection:(isMobile)&&"column"||"row"
      }}
    >
      {isMobile ? (
        <img
          src="/Images/servicePage/exploreService/banner3.png"
          alt="banner"
          style={{
            position: "relative",
            left: "-310px",
            order:"2",
            maxWidth: "200%",
          }}
        />
      ) : (
        <img
          src="/Images/servicePage/exploreService/banner1.png"
          alt=""
          style={{
            position: "absolute",
            left: isTab ? "-418px" : "-600px",
            display: isMobile && "none",
          }}
        />
      )}

      <div
        className="flex relative flex-col"
        style={{
          alignItems: "flex-start",
          width: "400px",
          flexWrap: "wrap",
          paddingRight: isMobile && "30px",
          width:(isMobile)&&"100%"||"40%",
          marginRight:isTab?"40px":"0px"
        }}
      >
       <div className="flex h-max items-center mb-2">
       <h3
          style={{
            display:"flex",
            alignItems:"center",
            fontSize: isMobile ? "20px" : "35px",
            fontWeight:"500",
          }}
        >Why<span className="text-[#1C6ED0] ml-2 mr-2"> Startup </span>Fails?</h3>

        <img
          src="/Images/servicePage/exploreService/Ellipse.png"
          alt="textc"
          style={{
            display: isMobile && "none",
            width:isTab&&"50px"||"100px",
            height:isTab&&"50px"||"100px",
            marginLeft:"20px",
          }}
        />

       </div>
    
        <p
          style={{
            textAlign: "start",
            fontSize: (isMobile||isTab) ? "14px" : "25px",
            color: "#5b6569",
            fontWeight:"400",
            marginBottom: "14px",
            width:"90%"
          }}
        >
          Our core focus is on providing essential business services that
          support the foundational needs to improve the chances of startup
          success.{" "}
        </p>
        <p
          style={{
            textAlign: "start",
            fontSize: (isMobile||isTab) ? "14px" : "25px",
            fontWeight:"400",
            color: "#5b6569",
            marginBottom: isMobile ? "30px" : "14px",
            width:"90%"
          }}
        >
          We are addressing issues that causes startup failures.
        </p>
        <button className="bg-[#407BFF] text-white hover:bg-[#2852ac]">
          <p className="font-semibold" style={{fontSize:(isMobile||isTab)&&"14px"||"25px"}}>Explore Services</p>
        </button>
      </div>
    </div>
  );
};

export default ServicePageExplore;
