import React from "react";

const Service = () => {
  return (
    <div className="!pt-5 !pb-0 !px-5 flex flex-col gap-2">
      <h2 className="text-[20px] font-semibold !text-start md:text-[35px] md:!pt-10 ">Benefits of Idea Validation Service</h2>
      {/* <hr /> */}
      <div className="flex overflow-x-scroll !py-2 !gap-3 md:!hidden">
        <img src="/Images/selfService/Benift_Triangle1.png" alt="idea" />
        <img src="/Images/selfService/Benift_Triangle2.png" alt="idea" />
        <img src="/Images/selfService/Benift_Triangle3.png" alt="idea" />
        <img src="/Images/selfService/Benift_Triangle4.png" alt="idea" />
        <img src="/Images/selfService/Benift_Triangle5.png" alt="idea" />
        <img src="/Images/selfService/Benift_Triangle6.png" alt="idea" />
      </div>
      <div className="hidden md:grid grid-cols-3 md:!pt-5 justify-between">
        <img src="/Images/selfService/Benift_Triangle1Big.png" alt="idea" />
        <img src="/Images/selfService/Benift_Triangle2Big.png" alt="idea" />
        <img src="/Images/selfService/Benift_Triangle3Big.png" alt="idea" />
        <img src="/Images/selfService/Benift_Triangle4Big.png" alt="idea" />
        <img src="/Images/selfService/Benift_Triangle5Big.png" alt="idea" />
        <img src="/Images/selfService/Benift_Triangle6Big.png" alt="idea" />
      </div>
    </div>
  );
};

export default Service;
