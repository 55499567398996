import React from "react";
import { guidanceDomain } from "../../../data/guidanceSection/guidanceDomain";
import { useMediaQuery } from "@mui/material";
// import subcategory

const GuidanceDomainSection = () => {
  const isMobile = useMediaQuery("(max-width:724px)");
  const isTab = useMediaQuery("(max-width:1024px)");
  const isLaptop = useMediaQuery("(max-width:1224px)");
  return (
    <div className="" style={{ marginTop: isMobile ? "400px" :isTab? "600px":"320px", marginBottom: "105px" }}>
      <div
        className="flex gap-4 justify-between my-4"
        style={{ gap: isMobile && "2px" }}
      >
        <div
          className=""
          style={{
            width: isMobile ? "18%" : "35%",
            borderTop: "1px solid black",
          }}
        ></div>
        <h2
          className="text-[25px] font-semibold text-[#263238]"
          style={{ fontSize: isMobile && "16px" }}
        >
          Expert
          <span className="text-[#1C6ED0]"> Guidance Domains</span>
        </h2>
        <div
          className=""
          style={{
            width: isMobile ? "18%" : "35%",
            borderTop: "1px solid black",
          }}
        ></div>
      </div>
      <div
        className="flex flex-wrap justify-center hover-effect"
        style={{ columnGap: isMobile ? "12px" : "70px", rowGap: "30px" }}
      >
        {guidanceDomain.map((data) => (
          <div
            key={data.domain}
            className="flex gap-2 px-3 py-2 shadow-xl hover-effect-child"
            style={{
              backgroundColor: "#F3F9FF",
              borderRadius: "25px",
              boxShadow: isMobile
                ? "0px 2px 4px rgba(0, 0, 0, 0.1)"
                : "0px 4px 8px rgba(0, 0, 0, 0.2)",
              transition: "transform 0.2s ease-in-out",
            }}
            onMouseOver={(e) => (e.target.style.transform = "scale(1.05)")}
            onMouseOut={(e) => (e.target.style.transform = "scale(1)")}
          >
            <img
              src={data.image}
              alt="symbol"
              style={{ width: isMobile ? "15px" : "18px" }}
            />
            <h1 style={{ fontSize: isMobile ? "10px" : "18px" }}>
              {data.domain}
            </h1>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GuidanceDomainSection;
